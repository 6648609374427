<template>
    <div>
        <h2 class="mb-1">General Report</h2>

        <div style="display: flex; flex-wrap: wrap; align-items: center;">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="prevRange()"
            >
                <feather-icon icon="ChevronLeftIcon" />
            </b-button>

            <b-form-group
                label="Start Date"
                class="mr-50 w-10"
            >
                <date-picker v-model="startDate" type="month" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-if="selectedRangeDate === 'month' || selectedRangeDate === 'quarter'"></date-picker>
                <date-picker v-model="startDate" type="year" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="selectedRangeDate === 'year'"></date-picker>
                <date-picker v-model="startDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="!selectedRangeDate || selectedRangeDate === 'custom'"></date-picker>
            </b-form-group>
            <b-form-group
                label="End Date"
                class="mr-50"
            >
                <date-picker v-model="endDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" :disabled="selectedRangeDate === 'month' || selectedRangeDate === 'quarter' || selectedRangeDate === 'year' ? true : false"></date-picker>
            </b-form-group>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon ml-0"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="nextRange()"
            >
                <feather-icon icon="ChevronRightIcon" />
            </b-button>
            <div :class="!selectedRangeDate ? 'pl-1' : 'pl-1'">
                <b-form-group>
                    <b-form-radio-group
                        v-model="selectedRangeDate"
                        button-variant="outline-primary range-date"
                        buttons
                        class="p-0 mr-2"
                    >
                        <b-form-radio style="height: 37px; padding: 11px;" v-for="(option,index) in optionsRangeDate" :key="index" :value="option.value">{{option.text}}</b-form-radio>
                        <b-form-radio style="height: 37px; padding: 10px;" v-if="selectedRangeDate" value="x">x</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <b-form-group
                label="Clinician Name"
            >
                <v-select
                    v-model="clinicianName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Clinician Name"
                    label="full"
                    :dense="true"
                    :options="optionsClinicianName"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                >
                </v-select>
            </b-form-group>
            <b-form-group
                label="Client Name"
            >
                <v-select
                    v-model="clientName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Client Name"
                    label="value"
                    :dense="true"
                    :options="optionsClientName"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                >
                </v-select>
            </b-form-group>
            <b-form-group
                label="Program"
            >
                <b-form-select
                    v-model="clinicianProgram"
                    placeholder="Any Program"
                    inline
                    :options="optionsProgram"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="min-width: 200px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group>
        </div>
        <b-row class="mb-0" style="background: #fdfdfd; border-radius: 10px; padding: 10px;" v-if="expandFilter">
            <b-col md="6">
                <b-row>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            label="Is insurance utilized for claim?"
                        >
                            <v-select
                                v-model="isInsuranceUtilizedForClaim"
                                placeholder="Is insurance utilized for claim?"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsIsInsuranceUtilizedForClaim"
                                style="background: #fff;"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            label="Client Progress This Month"
                        >
                            <v-select
                                v-model="clientProgressThisMonth"
                                placeholder="Client Progress This Month"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsClientProgressThisMonth"
                                style="background: #fff;"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            label="Did client provide copay?"
                        >
                            <v-select
                                v-model="didClientProvideCopay"
                                placeholder="Did client provide copay?"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsDidClientProvideCopay"
                                style="background: #fff;"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            label="Did client utilize sliding scale?"
                        >
                            <v-select
                                v-model="didClientUtilizeSlidingScale"
                                placeholder="Did client utilize sliding scale?"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsDidClientUtilizeSlidingScale"
                                style="background: #fff;"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            label="Region"
                        >
                            <v-select
                                v-model="region"
                                placeholder="Region"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsRegion"
                                style="background: #fff;"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="mb-25" v-if="region === 'NYC'">
                        <b-form-group
                            label="Borough"
                        >
                            <v-select
                                v-model="clinicianBorough"
                                placeholder="Borough"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsBorough"
                                style="background: #fff;"
                            />
                        </b-form-group>
                    </b-col>
                    <!-- <b-col
                        md="6"
                    >
                        <b-form-group
                            label="Client Reports Satisfaction with Treatment Sessions"
                        >
                            <v-select
                                v-model="clientReportsSatisfactionWithTreatmentSessions"
                                placeholder="Client Reports Satisfaction with Treatment Sessions"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="optionsClientReportsSatisfactionWithTreatmentSessions"
                                style="background: #fff;"
                            />
                        </b-form-group>
                    </b-col> -->
                </b-row>
            </b-col>
            <b-col md="6" style="background: #f2f2f2; border-radius: 10px;">
                <b-row>
                    <b-col 
                        md="6"
                    >
                        <b-form-group
                            label="# of Individual In-Person Sessions"
                        >
                            <cleave
                                v-model="noIndividualSessions"
                                class="form-control"
                                :raw="false"
                                :options="options.block"
                                placeholder="# of Individual In-Person Sessions"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            label="# of Group Sessions"
                        >
                            <cleave
                                v-model="noGroupSessions"
                                class="form-control"
                                :raw="false"
                                :options="options.block"
                                placeholder="# of Group Sessions"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            label="# of Remote Sessions"
                        >
                            <cleave
                                v-model="noRemoteSessions"
                                class="form-control"
                                :raw="false"
                                :options="options.block"
                                placeholder="# of Remote Sessions"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            label="# of Assessment Sessions"
                        >
                            <cleave
                                v-model="noAssessmentSessions"
                                class="form-control"
                                :raw="false"
                                :options="options.block"
                                placeholder="# of Assessment Sessions"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            label="# of Couple Sessions"
                        >
                            <cleave
                                v-model="noCoupleSessions"
                                class="form-control"
                                :raw="false"
                                :options="options.block"
                                placeholder="# of Couple Sessions"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="6"
                    >
                        <b-form-group
                            label="# of Family Sessions"
                        >
                            <cleave
                                v-model="noFamilySessions"
                                class="form-control"
                                :raw="false"
                                :options="options.block"
                                placeholder="# of Family Sessions"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
            <!-- <b-col
                md="2"
            >
                <b-form-group
                    label="Progress"
                >
                    <v-select
                        v-model="progress"
                        placeholder="Progress"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="optionsClientProgress"
                        style="background: #fff;"
                    />
                </b-form-group>
            </b-col>
            <b-col
                md="2"
            >
                <b-form-group
                    label="Language"
                >
                    <v-select
                        v-model="language"
                        placeholder="Language"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="optionsLanguage"
                        style="background: #fff;"
                    />
                </b-form-group>
            </b-col>
            <b-col
                md="2"
            >
                <b-form-group
                    label="Insurance"
                >
                    <v-select
                        v-model="insurance"
                        placeholder="Insurance"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="optionsInsurance"
                        style="background: #fff;"
                    />
                </b-form-group>
            </b-col> -->
        </b-row>
        <b-button
            v-if="!expandFilter"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            @click="expandFilter = true"
            class="mb-1"
        >
            <feather-icon
                icon="ChevronDownIcon"
                class="mr-50"
            />
            <span class="align-middle">Advanced</span>
        </b-button>
        <b-button
            v-else
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            @click="expandFilter = false"
            class="mb-1"
        >
            <feather-icon
                icon="ChevronUpIcon"
                class="mr-50"
            />
            <span class="align-middle">Minimize</span>
        </b-button>

        <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="mb-1 float-right"
            @click="activeFilterFunction"
        >
            <feather-icon
                icon="Xicon"
                class="mr-50"
            />
            <span class="align-middle">Reset filters</span>
        </b-button>

        <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon float-right"
            @click="functionFilters"
        >
            <feather-icon icon="SearchIcon" />
        </b-button> -->

        <b-row>
            <!-- Total Group Sessions -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ totalGroupSessions && totalGroupSessions !== 'NaN' ? totalGroupSessions.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Group Sessions</span>
                    </div>
                    <b-avatar
                        :variant="`light-primary`"
                        size="45"
                    >
                        <feather-icon
                            size="21"
                            icon="UsersIcon"
                        />
                    </b-avatar>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Individual Sessions -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ totalIndividualSessions && totalIndividualSessions !== 'NaN' ? totalIndividualSessions.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Individual Sessions</span>
                    </div>
                    <b-avatar
                        :variant="`light-primary`"
                        size="45"
                    >
                        <feather-icon
                            size="21"
                            icon="UserIcon"
                        />
                    </b-avatar>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Remote Sessions -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ totalRemoteSessions && totalRemoteSessions !== 'NaN' ? totalRemoteSessions.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Remote Sessions</span>
                    </div>
                    <b-avatar
                        :variant="`light-primary`"
                        size="45"
                    >
                        <feather-icon
                            size="21"
                            icon="PhoneIcon"
                        />
                    </b-avatar>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Assessment Sessions -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ totalAssessmentSessions && totalAssessmentSessions !== 'NaN' ? totalAssessmentSessions.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Assessment Sessions</span>
                    </div>
                    <b-avatar
                        :variant="`light-primary`"
                        size="45"
                    >
                        <feather-icon
                            size="21"
                            icon="CheckIcon"
                        />
                    </b-avatar>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Family Sessions -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ totalFamilySessions && totalFamilySessions !== 'NaN' ? totalFamilySessions.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Family Sessions</span>
                    </div>
                    <b-avatar
                        :variant="`light-primary`"
                        size="45"
                    >
                        <feather-icon
                            size="21"
                            icon="GridIcon"
                        />
                    </b-avatar>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Couple Sessions -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ totalCoupleSessions && totalCoupleSessions !== 'NaN' ? totalCoupleSessions.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Couple Sessions</span>
                    </div>
                    <b-avatar
                        :variant="`light-primary`"
                        size="45"
                    >
                        <feather-icon
                            size="21"
                            icon="UsersIcon"
                        />
                    </b-avatar>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <!-- Total Non-English Clients -->
            <!-- <b-col
                lg="6"
                md="6"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="loadTotalClients">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else> -->
                        <!-- {{ totalNonEnglishClients.toLocaleString('en-US') }} -->
                        <!---
                        </h2>
                        <span>Total Non-English Clients</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- Client Satisfaction -->
            <b-col
                lg="12"
                md="12"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                            <!-- {{countClientSatisfaction}} - {{totalClaims}} -->
                        {{ ((countClientSatisfaction*100)/totalClaims).toFixed(2) }}%
                        </h2>
                        <span>Client Satisfaction</span>
                    </div>
                    <b-avatar
                        :variant="`light-primary`"
                        size="45"
                    >
                        <feather-icon
                            size="21"
                            icon="PercentIcon"
                        />
                    </b-avatar>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-card>
            <h5>Client Progress</h5>
            <hr>
            <b-row>
                <!-- Improved -->
                <b-col
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ countImproved && countImproved !== 'NaN' ? countImproved.toLocaleString('en-US') : 0}}
                            </h2>
                            <span>Improved</span>
                        </div>
                        <b-avatar
                            :variant="`light-primary`"
                            size="45"
                        >
                            <feather-icon
                                size="21"
                                icon="ArrowUpIcon"
                            />
                        </b-avatar>
                        </b-card-body>
                    </b-card>
                </b-col>

                <!-- No Change -->
                <b-col
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ countNoChange && countNoChange !== 'NaN' ? countNoChange.toLocaleString('en-US') : 0 }}
                            </h2>
                            <span>No Change</span>
                        </div>
                        <b-avatar
                            :variant="`light-primary`"
                            size="45"
                        >
                            <feather-icon
                                size="21"
                                icon="MinusIcon"
                            />
                        </b-avatar>
                        </b-card-body>
                    </b-card>
                </b-col>

                <!-- Declined -->
                <b-col
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ countDeclined && countDeclined !== 'NaN' ? countDeclined.toLocaleString('en-US') : 0 }}
                            </h2>
                            <span>Declined</span>
                        </div>
                        <b-avatar
                            :variant="`light-primary`"
                            size="45"
                        >
                            <feather-icon
                                size="21"
                                icon="ArrowDownIcon"
                            />
                        </b-avatar>
                        </b-card-body>
                    </b-card>
                </b-col>

                <!-- N/A -->
                <b-col
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ countNA && countNA !== 'NaN' ? countNA.toLocaleString('en-US') : 0 }}
                            </h2>
                            <span>N/A</span>
                        </div>
                        <b-avatar
                            :variant="`light-primary`"
                            size="45"
                        >
                            <feather-icon
                                size="21"
                                icon="SlashIcon"
                            />
                        </b-avatar>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-card>

        <b-card>
            <h5>Insurance</h5>
            <hr>
            <b-row>
                <!-- Total Clients Insured -->
                <b-col
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ countTotalClientsInsured.toLocaleString('en-US') }}
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                style="padding: 3px; border-radius: 5px; font-size: 12px; margin-left: 10px; font-weight: bold;"
                            >
                            Rate: {{ countTotalClientsInsuredRate && countTotalClientsInsuredRate !== 'NaN' ? countTotalClientsInsuredRate.toFixed(2) : 0 }}%
                            </b-button>
                            <!-- 0 -->
                            </h2>
                            <span>Total Clients Insured</span>
                        </div>
                        </b-card-body>
                    </b-card>
                </b-col>

                <!-- Total Sliding Scale -->
                <b-col
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            {{ countTotalSlidingScale && countTotalSlidingScale !== 'NaN' ? countTotalSlidingScale.toLocaleString('en-US') : 0 }}
                            </h2>
                            <span>Total Sliding Scale</span>
                        </div>
                        </b-card-body>
                    </b-card>
                </b-col>

                <!-- Average Co-pay Per Session -->
                <b-col
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <div class="text-primary" v-if="isBusy">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <div class="text-primary" v-else-if="activeFilters && firstLoadData">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <h2 class="mb-25 font-weight-bolder" v-else>
                            ${{ countAverageCopayPerSession && countAverageCopayPerSession !== 'NaN' ? countAverageCopayPerSession.toFixed(2) : '0.00' }}
                            </h2>
                            <span>Average Co-pay Per Session</span>
                        </div>
                        </b-card-body>
                    </b-card>
                </b-col>

                <!-- Average Co-pay Increased? -->
                <!-- <b-col
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <h2 class="mb-25 font-weight-bolder"> -->
                            <!-- {{ countAverageCopayIncreased }} -->
                            <!-- </h2>
                            <span>Average Co-pay Increased?</span>
                        </div>
                        </b-card-body>
                    </b-card>
                </b-col> -->

                <!-- Cost to CFE Program Decreased? -->
                <!-- <b-col
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <b-card no-body style="border: 1px solid #ecebed;">
                        <b-card-body class="d-flex justify-content-between align-items-center">
                        <div class="truncate">
                            <h2 class="mb-25 font-weight-bolder"> -->
                            <!-- {{ countCosttoCFEProgramDecreased }} -->
                            <!-- </h2>
                            <span>Cost to CFE Program Decreased?</span>
                        </div>
                        </b-card-body>
                    </b-card>
                </b-col> -->
            </b-row>
        </b-card>
        
        <div
            class="mb-1 btn btn-default"
            style="background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 1;"
            @click="checkReadyToExport"
            v-if="isDataSpecialist && json_data.length === 0"
        >
            CSV Export
            <b-spinner small v-if="!readyToExport" />
        </div>
        <download-csv 
            :data="json_data"
            class="mb-1 btn btn-default"
            name="reports-general.csv"
            :style="'background: #94221e; color: #fff; cursor: pointer; opacity: 1;'"
            delimiter=","
            v-if="isDataSpecialist && json_data.length >= 1"
        >
        <!-- v-if="isDataSpecialist" readyToExport ? 'background: #94221e; color: #fff; cursor: pointer; opacity: 1;' : 'background: #94221e; color: #fff; cursor: no-drop; opacity: 0.5;'-->
            CSV Export
            <!-- <b-spinner small v-if="readyToExport" /> -->
        </download-csv>

        <div>
            <b-card>
                <h4>Individual Clients Seen</h4>
                <b-table
                    :items="arrayClientsIndividualClientsSeen"
                    :fields="fieldsTable"
                    responsive="sm"
                    thead-class="d-none"
                    :busy="isBusyClientsSeen"
                    v-if="arrayClientsIndividualClientsSeen.length >= 1"
                >
                    <template #table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong class="ml-1">Loading...</strong>
                        </div>
                    </template>
                    <!-- Column: countClaims -->
                    <template #cell(countClaims)="data">
                        <h4>{{ data.item.countClaims }}</h4>
                    </template>
                </b-table>
                <b-table
                    :items="arrayFiltradoReportsGeneralIndividualClientsSeen"
                    :fields="fieldsTable"
                    responsive="sm"
                    thead-class="d-none"
                    :busy="isBusyClientsSeen"
                    v-else
                >
                    <template #table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong class="ml-1">Loading...</strong>
                        </div>
                    </template>
                    <!-- Column: countClaims -->
                    <template #cell(countClaims)="data">
                        <h4>{{ data.item.countClaims }}</h4>
                    </template>
                </b-table>
            </b-card>
        </div>
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BSpinner, BFormRadioGroup, BFormRadio, BContainer,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            options: {
                block: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'false',
                    delimiter: ''
                },
            },
            isDataSpecialist: false,
            expandFilter: false,
            readyToExport: true,

            // filters
            startDate: null,
            endDate: null,
            clinicianName: '',
            clientName: '',
            noIndividualSessions: null,
            noGroupSessions: null,
            noRemoteSessions: null,
            noAssessmentSessions: null,
            noCoupleSessions: null,
            noFamilySessions: null,
            isInsuranceUtilizedForClaim: '',
            clientProgressThisMonth: '',
            clientReportsSatisfactionWithTreatmentSessions: '',
            didClientProvideCopay: '',
            didClientUtilizeSlidingScale: '',
            region: '',

            // old filters
            language: '',
            progress: '',
            insurance: '',

            // data reports
            totalClaims: 0,
            countClientSatisfaction: 0,
            countNA: 0,
            countNoChange: 0,
            countImproved: 0,
            countDeclined: 0,
            countTotalSlidingScale: 0,
            countAverageCopayPerSession: 0,
            arrayClientsIndividualClientsSeen: [],
            arrayFiltradoReportsGeneralIndividualClientsSeen: [],

            // vars atomic
            countTotalClientsInsured: 0,
            countTotalClientsInsuredRate: 0,
            countAverageCopayPerSessionAtomic: 0,
            totalInsuranceReimbursementRateForIndividualSession: 0,

            // json export csv
            json_data: [],

            // options
            optionsClinicianName: [],
            optionsClientName: [],
            optionsIsInsuranceUtilizedForClaim: ['Yes', 'No'],
            optionsClientProgressThisMonth: ['N/A','Declined','Improved','No Change'],
            optionsClientReportsSatisfactionWithTreatmentSessions: ['Yes', 'No'],
            optionsDidClientProvideCopay: ['Yes', 'No'],
            optionsDidClientUtilizeSlidingScale: ['Yes', 'No'],
            optionsRegion: [],
            clinicianBorough: null,
            optionsBorough: [
                // 'Any Borough',
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island',
            ],
            // optionsRegion: [
            //     'Bronx',
            //     'Brooklyn',
            //     'Central',
            //     'Finger Lakes',
            //     'Long Island',
            //     'Manhattan',
            //     'Mid-Hudson',
            //     'Northeast',
            //     'Queens',
            //     'Staten Island',
            //     'Western'
            // ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],

            // settings table and edit view
            statusEdit: false,
            itemsActivity: [],
            fieldsTable: [
                { key: 'dateFormat', sortable: false },
                { key: 'countClaims', sortable: false },
            ],
            // fieldsTable: [
            //     { key: 'clientID', sortable: false },
            //     { key: 'clientName', sortable: false },
            //     { key: 'status', sortable: false },
            //     { key: 'contactDate', sortable: false },
            //     { key: 'admissionDate', sortable: false },
            //     { key: 'dischargeDate', sortable: false },
            //     // { key: 'actions', sortable: false },
            // ],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,
            selectedRangeDate: 'month',
            optionsRangeDate: [
                { text: 'Month', value: 'month' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Year', value: 'year' },
                { text: 'Custom', value: 'custom' },
            ],
            clinicianProgram: null,
            optionsProgram: [],

            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            totalAssessmentSessions: 0,
            totalFamilySessions: 0,
            totalCoupleSessions: 0,
            totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,
            totalNonEnglishClientsStatic: 0,
            totalCopayWithoutFilters: 0,

            // overlay data
            rangeDinamic: false,
            isBusy: true,
            isBusyClientsSeen: true,
            loadTotalClients: true,
            activeFilters: false,
            firstLoadDOM: true,
            firstLoadData: true,
            seenClients: []
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
        BContainer,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,
        DatePicker,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters(['arrayFiltradoReportsGeneral','arrayFiltradoReportsGeneralClaims']),
        ...mapState(['searchReportsGeneralStartDateState','searchReportsGeneralEndDateState','searchReportsGeneralClinicianNameState','searchReportsGeneralClientNameState','searchReportsGeneralnoIndividualSessionsState','searchReportsGeneralnoGroupSessionsState','searchReportsGeneralnoRemoteSessionsState','searchReportsGeneralnoAssessmentSessionsState','searchReportsGeneralnoCoupleSessionsState','searchReportsGeneralnoFamilySessionsState','searchReportsGeneralisInsuranceUtilizedForClaimState','searchReportsGeneralclientProgressThisMonthState','searchReportsGeneralclientReportsSatisfactionWithTreatmentSessionsState','searchReportsGeneraldidClientProvideCopayState','searchReportsGeneraldidClientUtilizeSlidingScaleState','searchReportsGeneralRegionState','searchReportsGeneralProgramState'])
        // ,'arrayFiltradoReportsGeneralIndividualClientsSeen'
    },
    watch: {
        selectedRangeDate(val) {
            this.functionDateRange(val)
        },
        startDate(val) {
            // val ? this.activeFilters = true : this.activeFilters = false
            // console.log(val)
            this.checkActiveFilters()
            this.isBusy = true
            this.searchReportsGeneralStartDate(val)
            this.functionDateRange(this.selectedRangeDate)
            if(!val) {
                this.filterRestard()
            }
            this.rangeDinamic ? this.rangeDinamic = false : ''
            // this.selectedRangeDate = 'custom'
            
            // this.totalNonEnglishClients = 0
        },
        endDate(val) {
            // console.log(val)
            this.checkActiveFilters()
            this.isBusy = true
            this.searchReportsGeneralEndDate(val)
            if(!val) {
                this.filterRestard()
            }

            // this.totalNonEnglishClients = 0
        },
        clinicianName(val) {
            this.checkActiveFilters()
            // if(val) {
            //     this.searchReportsGeneralClinicianName(val.uid)
            // } else {
            //     this.filterRestard()
            //     this.searchReportsGeneralClinicianName('')
            // }
            if(val) {
                if(val.value === 'All Clinicians') {
                    this.clinicianName = null
                } else {
                    this.searchReportsGeneralClinicianName(val.uid)
                }
            } else {
                this.filterRestard()
                this.searchReportsGeneralClinicianName('')
            }
            // this.totalNonEnglishClients = 0
        },
        clientName(val) {
            this.checkActiveFilters()
            // if(val) {
            //     this.searchReportsGeneralClientName(val.uid)
            // } else {
            //     this.filterRestard()
            //     this.searchReportsGeneralClientName('')
            // }
            // console.log(val)
            if(val) {
                if(val.value === 'All Clients') {
                    this.clientName = null
                    // this.apiLoaded = false
                } else {
                    this.searchReportsGeneralClientName(val.uid)
                }
            } else {
                // this.apiLoaded = false
                this.filterRestard()
                this.searchReportsGeneralClientName('')
            }
            // this.totalNonEnglishClients = 0
        },
        noIndividualSessions(val) {
            this.checkActiveFilters()
            if(!val) {
                this.filterRestard()
            }
            // this.totalNonEnglishClients = 0

            this.searchReportsGeneralnoIndividualSessions(val)
        },
        noGroupSessions(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }

            this.searchReportsGeneralnoGroupSessions(val)
        },
        noRemoteSessions(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }

            this.searchReportsGeneralnoRemoteSessions(val)
        },
        noAssessmentSessions(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }

            this.searchReportsGeneralnoAssessmentSessions(val)
        },
        noCoupleSessions(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }

            this.searchReportsGeneralnoCoupleSessions(val)
        },
        noFamilySessions(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            this.searchReportsGeneralnoFamilySessions(val)
        },
        isInsuranceUtilizedForClaim(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }

            this.searchReportsGeneralisInsuranceUtilizedForClaim(val)
        },
        clientProgressThisMonth(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }

            this.searchReportsGeneralclientProgressThisMonth(val)
        },
        clientReportsSatisfactionWithTreatmentSessions(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }

            this.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions(val)
        },
        didClientProvideCopay(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }

            this.searchReportsGeneraldidClientProvideCopay(val)
        },
        didClientUtilizeSlidingScale(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }

            this.searchReportsGeneraldidClientUtilizeSlidingScale(val)
        },
        region(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }
            
            this.searchReportsGeneralRegion(val)
        },
        clinicianBorough(val) {
            this.checkActiveFilters()
            // this.totalNonEnglishClients = 0
            if(!val) {
                this.filterRestard()
            }
            
            this.searchReportsGeneralRegion(val === 'Any Borough' ? null : val)
        },
        progress(val) {
            this.checkActiveFilters()
            this.searchReportsGeneralProgress(val)
        },
        language(val) {
            this.checkActiveFilters()
            this.searchReportsGeneralLanguage(val)
        },
        clinicianProgram(val) {
            this.checkActiveFilters()
            this.searchReportsGeneralProgram(val)
        },
        arrayFiltradoReportsGeneralClaims(val) {
            this.totalGroupSessions = 0
            this.totalIndividualSessions = 0
            this.totalRemoteSessions = 0
            this.totalAssessmentSessions = 0
            this.totalFamilySessions = 0
            this.totalCoupleSessions = 0
            this.countClientSatisfaction = 0
            this.countNA = 0
            this.countNoChange = 0
            this.countImproved = 0
            this.countDeclined = 0
            this.countTotalSlidingScale = 0

            this.countAverageCopayPerSession = 0
            this.countAverageCopayPerSessionAtomic = 0

            this.improved = 0

            this.totalClaims = val.length
            var arrayUniqueClients = []
            // console.log(val.length)
            this.countTotalClientsInsured = 0
            this.countTotalClientsInsuredRate = 0
            var totalCountAverageCopayPerSession = 0
            var totalSectionItemsFormRepeater = 0
            var countTotalClientsInsuredAt = 0
            var insRate = 0
            var counterInsRate = 0
            var arrayExport = []
            this.readyToExport = true
            var totalCopay = 0
            val.forEach(itemClaim => {
                totalCopay = itemClaim.totalCopay
                itemClaim.itemsFormRepeater ? itemClaim.itemsFormRepeater : itemClaim.itemsFormRepeater = []

                if(itemClaim.clientInsurance !== 'None' && itemClaim.clientInsurance !== 'no insurance' && itemClaim.clientInsurance !== 'N/A') {
                    this.countTotalClientsInsured += 1
                }

                if(itemClaim.approvalStatus === 'approved' || itemClaim.approvalStatus === 'paid') {
                    // Total Group Sessions
                    this.totalGroupSessions += parseInt(itemClaim.totalGroupSessions, 10)

                    // Total Individual Sessions
                    this.totalIndividualSessions += parseInt(itemClaim.totalIndividualSessions, 10)

                    // Total Remote Sessions
                    this.totalRemoteSessions += parseInt(itemClaim.totalRemoteSessions, 10)

                    // Total Assessment Sessions
                    this.totalAssessmentSessions += parseInt(itemClaim.totalAssessmentSessions, 10)

                    // Total Family Sessions
                    this.totalFamilySessions += parseInt(itemClaim.totalFamilySessions, 10)

                    // Total Couple Sessions
                    this.totalCoupleSessions += parseInt(itemClaim.totalCoupleSessions, 10)

                    if(itemClaim.clientReportsSatisfactionWithTreatmentSessions) {
                        // Client Satisfaction
                        if(itemClaim.clientReportsSatisfactionWithTreatmentSessions === 'Yes') {
                            this.countClientSatisfaction += 1
                        }
                    }

                    // Improved
                    if(itemClaim.clientProgressThisMonth === 'Improved') {
                        this.countImproved += 1
                    }

                    // No Change
                    if(itemClaim.clientProgressThisMonth === 'No Change') {
                        this.countNoChange += 1
                    }

                    // Declined
                    if(itemClaim.clientProgressThisMonth === 'Declined') {
                        this.countDeclined += 1
                    }

                    // N/A
                    if(itemClaim.clientProgressThisMonth && itemClaim.clientProgressThisMonth.trim() === 'N/A') {
                        this.countNA += 1
                    }
                }

                if(itemClaim.insuranceReimbursementRateForIndividualSessions) {
                    counterInsRate += 1
                    insRate += itemClaim.insuranceReimbursementRateForIndividualSessions ? parseFloat(itemClaim.insuranceReimbursementRateForIndividualSessions) : 0
                }

                if(itemClaim.clientInsurance === 'Sliding scale') {
                    this.countTotalSlidingScale += 1
                }
                
                if(itemClaim.amountBilledToCFE) {
                    totalSectionItemsFormRepeater += 1
                    this.countAverageCopayPerSessionAtomic += parseFloat(itemClaim.amountBilledToCFE)
                }

                this.improved += 1

                if(this.region === 'NYC' && this.clinicianBorough && this.clinicianBorough !== 'Any Borough') {
                    arrayExport.push({
                        totalGroupSessions: itemClaim.totalGroupSessions,
                        totalIndividualSessions: itemClaim.totalIndividualSessions,
                        totalRemoteSessions: itemClaim.totalRemoteSessions,
                        totalAssessmentSessions: itemClaim.totalAssessmentSessions,
                        totalFamilySessions: itemClaim.totalFamilySessions,
                        totalCoupleSessions: itemClaim.totalCoupleSessions,
                        // client: itemClaim.,
                        // clinician: itemClaim.,

                        // client information
                        clientID: itemClaim.clientID ? itemClaim.clientID : '',
                        insurance: itemClaim.clientInsurance ? itemClaim.clientInsurance : '',
                        county: itemClaim.county ? itemClaim.county : '',
                        language: itemClaim.language ? itemClaim.language : '',
                        // gender: itemClaim.gender ? itemClaim.gender : '',
                        program: itemClaim.clinicianProgram ? itemClaim.clinicianProgram : '',
                        didClientUtilizeSlidingScale: itemClaim.didClientUtilizeSlidingScale,
                        clientProgressThisMonth: itemClaim.clientProgressThisMonth,
                        clientReportsSatisfactionWithTreatmentSessions: itemClaim.clientReportsSatisfactionWithTreatmentSessions,
                        didClientProvideCopay: itemClaim.didClientProvideCopay ? itemClaim.didClientProvideCopay : '',
                        slidingScale: itemClaim.slidingScale,

                        approvalStatus: itemClaim.approvalStatus,
                        insuranceSession: itemClaim.insuranceSession,
                        amountBilledToCFE: itemClaim.amountBilledToCFE,
                        noIndividualSessions: itemClaim.noIndividualSessions,
                        noGroupSessions: itemClaim.noGroupSessions,
                        noRemoteSessions: itemClaim.noRemoteSessions,
                        noAssessmentSessions: itemClaim.noAssessmentSessions,
                        noCoupleSessions: itemClaim.noCoupleSessions,
                        noFamilySessions: itemClaim.noFamilySessions,
                        isInsuranceUtilizedForClaim: itemClaim.isInsuranceUtilizedForClaim,
                        regionClinician: 'NYC',
                        boroughClinician: this.clinicianBorough,
                        // didClientProvideCopay: itemClaim.,
                        // didClientUtilizeSlidingScale: itemClaim.,
                        insuranceReimbursementRateForIndividualSessions: itemClaim.insuranceReimbursementRateForIndividualSessions,
                        claimDate: itemClaim.claimDate ? moment(itemClaim.claimDate,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                        // claimMonthYear: itemClaim.,
                        // createdFormat: itemClaim.,
                        // created: itemClaim.,
                        // actualDate: itemClaim.,
                        // itemsFormRepeater: itemClaim.,
                    })
                } else if(this.region === 'NYC' && !this.clinicianBorough) {
                    arrayExport.push({
                        totalGroupSessions: itemClaim.totalGroupSessions,
                        totalIndividualSessions: itemClaim.totalIndividualSessions,
                        totalRemoteSessions: itemClaim.totalRemoteSessions,
                        totalAssessmentSessions: itemClaim.totalAssessmentSessions,
                        totalFamilySessions: itemClaim.totalFamilySessions,
                        totalCoupleSessions: itemClaim.totalCoupleSessions,
                        // client: itemClaim.,
                        // clinician: itemClaim.,

                        // client information
                        clientID: itemClaim.clientID ? itemClaim.clientID : '',
                        insurance: itemClaim.clientInsurance ? itemClaim.clientInsurance : '',
                        county: itemClaim.county ? itemClaim.county : '',
                        language: itemClaim.language ? itemClaim.language : '',
                        // gender: itemClaim.gender ? itemClaim.gender : '',
                        program: itemClaim.clinicianProgram ? itemClaim.clinicianProgram : '',
                        didClientUtilizeSlidingScale: itemClaim.didClientUtilizeSlidingScale,
                        clientProgressThisMonth: itemClaim.clientProgressThisMonth,
                        clientReportsSatisfactionWithTreatmentSessions: itemClaim.clientReportsSatisfactionWithTreatmentSessions,
                        didClientProvideCopay: itemClaim.didClientProvideCopay ? itemClaim.didClientProvideCopay : '',
                        slidingScale: itemClaim.slidingScale,

                        approvalStatus: itemClaim.approvalStatus,
                        insuranceSession: itemClaim.insuranceSession,
                        amountBilledToCFE: itemClaim.amountBilledToCFE,
                        noIndividualSessions: itemClaim.noIndividualSessions,
                        noGroupSessions: itemClaim.noGroupSessions,
                        noRemoteSessions: itemClaim.noRemoteSessions,
                        noAssessmentSessions: itemClaim.noAssessmentSessions,
                        noCoupleSessions: itemClaim.noCoupleSessions,
                        noFamilySessions: itemClaim.noFamilySessions,
                        isInsuranceUtilizedForClaim: itemClaim.isInsuranceUtilizedForClaim,
                        regionClinician: 'NYC',
                        boroughClinician: itemClaim.regionBorough ? itemClaim.regionBorough : itemClaim.regionClinician === 'Bronx' || itemClaim.regionClinician === 'Brooklyn' || itemClaim.regionClinician === 'Manhattan' || itemClaim.regionClinician === 'Queens' || itemClaim.regionClinician === 'Staten Island' ? itemClaim.regionClinician : '',
                        // didClientProvideCopay: itemClaim.,
                        // didClientUtilizeSlidingScale: itemClaim.,
                        insuranceReimbursementRateForIndividualSessions: itemClaim.insuranceReimbursementRateForIndividualSessions,
                        claimDate: itemClaim.claimDate ? moment(itemClaim.claimDate,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                        // claimMonthYear: itemClaim.,
                        // createdFormat: itemClaim.,
                        // created: itemClaim.,
                        // actualDate: itemClaim.,
                        // itemsFormRepeater: itemClaim.,
                    })
                } else {
                    arrayExport.push({
                        totalGroupSessions: itemClaim.totalGroupSessions,
                        totalIndividualSessions: itemClaim.totalIndividualSessions,
                        totalRemoteSessions: itemClaim.totalRemoteSessions,
                        totalAssessmentSessions: itemClaim.totalAssessmentSessions,
                        totalFamilySessions: itemClaim.totalFamilySessions,
                        totalCoupleSessions: itemClaim.totalCoupleSessions,
                        // client: itemClaim.,
                        // clinician: itemClaim.,

                        // client information
                        clientID: itemClaim.clientID ? itemClaim.clientID : '',
                        insurance: itemClaim.clientInsurance ? itemClaim.clientInsurance : '',
                        county: itemClaim.county ? itemClaim.county : '',
                        language: itemClaim.language ? itemClaim.language : '',
                        // gender: itemClaim.gender ? itemClaim.gender : '',
                        program: itemClaim.clinicianProgram ? itemClaim.clinicianProgram : '',
                        didClientUtilizeSlidingScale: itemClaim.didClientUtilizeSlidingScale,
                        clientProgressThisMonth: itemClaim.clientProgressThisMonth,
                        clientReportsSatisfactionWithTreatmentSessions: itemClaim.clientReportsSatisfactionWithTreatmentSessions,
                        didClientProvideCopay: itemClaim.didClientProvideCopay ? itemClaim.didClientProvideCopay : '',
                        slidingScale: itemClaim.slidingScale,

                        approvalStatus: itemClaim.approvalStatus,
                        insuranceSession: itemClaim.insuranceSession,
                        amountBilledToCFE: itemClaim.amountBilledToCFE,
                        noIndividualSessions: itemClaim.noIndividualSessions,
                        noGroupSessions: itemClaim.noGroupSessions,
                        noRemoteSessions: itemClaim.noRemoteSessions,
                        noAssessmentSessions: itemClaim.noAssessmentSessions,
                        noCoupleSessions: itemClaim.noCoupleSessions,
                        noFamilySessions: itemClaim.noFamilySessions,
                        isInsuranceUtilizedForClaim: itemClaim.isInsuranceUtilizedForClaim,
                        regionClinician: itemClaim.regionClinician,
                        // didClientProvideCopay: itemClaim.,
                        // didClientUtilizeSlidingScale: itemClaim.,
                        insuranceReimbursementRateForIndividualSessions: itemClaim.insuranceReimbursementRateForIndividualSessions,
                        claimDate: itemClaim.claimDate ? moment(itemClaim.claimDate,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                        // claimMonthYear: itemClaim.,
                        // createdFormat: itemClaim.,
                        // created: itemClaim.,
                        // actualDate: itemClaim.,
                        // itemsFormRepeater: itemClaim.,
                    })
                }
            })

            if(!this.firstLoadData) {
                if(this.startDate && this.endDate) {
                    if(this.firstLoadDOM) {
                        this.totalInsuranceReimbursementRateForIndividualSession = totalCopay
                    }
                    this.firstLoadDOM = false
                }
                if(this.firstLoadDOM) {
                    this.totalInsuranceReimbursementRateForIndividualSession = totalCopay ? totalCopay : this.countAverageCopayPerSessionAtomic
                    this.countAverageCopayPerSession = this.countAverageCopayPerSessionAtomic / totalSectionItemsFormRepeater
                } else {
                    if(this.countAverageCopayPerSessionAtomic > 0) {
                        if(this.activeFilters) {
                            this.countAverageCopayPerSession = totalCopay/this.countAverageCopayPerSessionAtomic
                        } else {
                            this.countAverageCopayPerSession = this.totalCopayWithoutFilters
                        }
                    } else {
                        this.countAverageCopayPerSession = 0
                    }
                    this.firstLoadDOM = false
                }
            }
            
            this.countTotalClientsInsuredRate = (this.countTotalClientsInsured*100)/this.improved
            
            if(!this.startDate && !this.endDate) {
                this.arrayClientsIndividualClientsSeen = []
                this.checkActiveFilters()
            } else {
                this.checkActiveFilters()
            }
            
            if(!this.activeFilters && this.firstLoadDOM) {
                if(this.countAverageCopayPerSession  && this.countAverageCopayPerSession >= 1) {
                    db.collection('reportGeneral').doc('counters').update({
                        totalGroupSessions: this.totalGroupSessions,
                        totalIndividualSessions: this.totalIndividualSessions,
                        totalRemoteSessions: this.totalRemoteSessions,
                        totalAssessmentSessions: this.totalAssessmentSessions,
                        totalFamilySessions: this.totalFamilySessions,
                        totalCoupleSessions: this.totalCoupleSessions,
                        countClientSatisfaction: this.countClientSatisfaction,
                        countNA: this.countNA,
                        countImproved: this.countImproved,
                        countNoChange: this.countNoChange,
                        countDeclined: this.countDeclined,
                        countTotalClientsInsured: this.countTotalClientsInsured,
                        countTotalSlidingScale: this.countTotalSlidingScale,
                        countAverageCopayPerSession: this.countAverageCopayPerSession,
                        totalClaims: this.totalClaims,
                    })
                } else {
                    db.collection('reportGeneral').doc('counters').update({
                        totalGroupSessions: this.totalGroupSessions,
                        totalIndividualSessions: this.totalIndividualSessions,
                        totalRemoteSessions: this.totalRemoteSessions,
                        totalAssessmentSessions: this.totalAssessmentSessions,
                        totalFamilySessions: this.totalFamilySessions,
                        totalCoupleSessions: this.totalCoupleSessions,
                        countClientSatisfaction: this.countClientSatisfaction,
                        countNA: this.countNA,
                        countImproved: this.countImproved,
                        countNoChange: this.countNoChange,
                        countDeclined: this.countDeclined,
                        countTotalClientsInsured: this.countTotalClientsInsured,
                        countTotalSlidingScale: this.countTotalSlidingScale,
                        // countAverageCopayPerSession: !this.countAverageCopayPerSession ? 0 : this.countAverageCopayPerSession,
                        totalClaims: this.totalClaims,
                    })
                }
            }
            if(!this.firstLoadData) {
                this.firstLoadDOM = false
            }

            this.json_data = arrayExport
            this.readyToExport = true
            this.isBusy = false
        },
        arrayFiltradoReportsGeneralIndividualClientsSeen(val) {
            // console.log(val.length)
        },
        arrayClientsIndividualClientsSeen(val) {
            // console.log(val,val.length)
        }
    },
    methods: {
        ...mapActions(['searchReportsGeneralStartDate','searchReportsGeneralEndDate','searchReportsGeneralClinicianName','searchReportsGeneralClientName','searchReportsGeneralnoIndividualSessions','searchReportsGeneralnoGroupSessions','searchReportsGeneralnoRemoteSessions','searchReportsGeneralnoAssessmentSessions','searchReportsGeneralnoCoupleSessions','searchReportsGeneralnoFamilySessions','searchReportsGeneralisInsuranceUtilizedForClaim','searchReportsGeneralclientProgressThisMonth','searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions','searchReportsGeneraldidClientProvideCopay','searchReportsGeneraldidClientUtilizeSlidingScale','searchReportsGeneralRegion','setSnapshotReportsGeneral','setSnapshotReportsGeneralClaims','setSnapshotReportsGeneralIndividualClientsSeen','searchReportsGeneralLanguage','searchReportsGeneralProgress','searchReportsGeneralProgram']),
        checkReadyToExport() {
            if(this.json_data.length === 0) {
                this.readyToExport = false
            } else {
                this.readyToExport = true
            }
        },
        prevRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).subtract(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
            }
        },
        nextRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).add(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
            }
        },
        functionFilters() {
            if(this.startDate) {
                this.searchReportsGeneralStartDate(this.startDate)
                // this.totalNonEnglishClients = 0
            } else {
                this.searchReportsGeneralStartDate('')
            }

            if(this.endDate) {
                this.searchReportsGeneralEndDate(this.endDate)
                // this.totalNonEnglishClients = 0
            } else {
                this.searchReportsGeneralEndDate('')
            }

            if(this.clinicianName) {
                if(this.clinicianName.value === 'All Clinicians') {
                    this.searchReportsGeneralClinicianName('')
                } else {
                    this.searchReportsGeneralClinicianName(this.clinicianName.uid)
                }
                // this.totalNonEnglishClients = 0
            } else {
                this.searchReportsGeneralClinicianName('')
            }

            if(this.clientName) {
                if(this.clinicianName.value === 'All Clients') {
                    this.searchReportsGeneralClientName('')
                    // this.apiLoaded = false
                } else {
                    this.searchReportsGeneralClientName(this.clientName.uid)
                }
                // this.totalNonEnglishClients = 0
            } else {
                // this.filterRestard()
                this.searchReportsGeneralClientName('')
            }

            if(this.noIndividualSessions) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneralnoIndividualSessions(this.noIndividualSessions)
            } else {
                this.searchReportsGeneralnoIndividualSessions('')
            }

            if(this.noGroupSessions) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneralnoGroupSessions(this.noGroupSessions)
            } else {
                this.searchReportsGeneralnoGroupSessions('')
            }

            if(this.noRemoteSessions) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneralnoRemoteSessions(this.noRemoteSessions)
            } else {
                this.searchReportsGeneralnoRemoteSessions('')
            }

            if(this.noAssessmentSessions) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneralnoAssessmentSessions(this.noAssessmentSessions)
            } else {
                this.searchReportsGeneralnoAssessmentSessions('')
            }

            if(this.noCoupleSessions) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneralnoCoupleSessions(this.noCoupleSessions)
            } else {
                this.searchReportsGeneralnoCoupleSessions('')
            }

            if(this.noFamilySessions) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneralnoFamilySessions(this.noFamilySessions)
            } else {
                this.searchReportsGeneralnoFamilySessions('')
            }

            if(this.isInsuranceUtilizedForClaim) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneralisInsuranceUtilizedForClaim(this.isInsuranceUtilizedForClaim)
            } else {
                this.searchReportsGeneralisInsuranceUtilizedForClaim('')
            }

            if(this.clientProgressThisMonth) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneralclientProgressThisMonth(this.clientProgressThisMonth)
            } else {
                this.searchReportsGeneralclientProgressThisMonth('')
            }

            if(this.clientReportsSatisfactionWithTreatmentSessions) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions(this.clientReportsSatisfactionWithTreatmentSessions)
            } else {
                this.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions('')
            }

            if(this.didClientProvideCopay) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneraldidClientProvideCopay(this.didClientProvideCopay)
            } else {
                this.searchReportsGeneraldidClientProvideCopay('')
            }

            if(this.didClientUtilizeSlidingScale) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneraldidClientUtilizeSlidingScale(this.didClientUtilizeSlidingScale)
            } else {
                this.searchReportsGeneraldidClientUtilizeSlidingScale('')
            }

            if(this.region) {
                // this.totalNonEnglishClients = 0
                this.searchReportsGeneralRegion(this.region)
            } else {
                this.searchReportsGeneralRegion('')
            }

        //     if(!this.startDate && !this.endDate && !this.clinicianName && !this.clientName && !this.noIndividualSessions && !this.noGroupSessions && !this.noRemoteSessions && !this.noAssessmentSessions && !this.noCoupleSessions && !this.noFamilySessions && !this.isInsuranceUtilizedForClaim && !this.clientProgressThisMonth && !this.clientReportsSatisfactionWithTreatmentSessions && !this.didClientProvideCopay && !this.didClientUtilizeSlidingScale && !this.region) {
        //         this.filterRestard()
        //     }
        },
        checkActiveFilters() {
            this.totalNonEnglishClients = this.totalNonEnglishClientsStatic
            if(this.startDate || this.endDate || this.clinicianName || this.clientName || this.noIndividualSessions || this.noGroupSessions || this.noRemoteSessions || this.noAssessmentSessions || this.noCoupleSessions || this.noFamilySessions || this.isInsuranceUtilizedForClaim || this.clientProgressThisMonth || this.clientReportsSatisfactionWithTreatmentSessions || this.didClientProvideCopay || this.didClientUtilizeSlidingScale || this.region || this.progress || this.language || this.clinicianProgram) {
                this.activeFilters = true
                // this.totalNonEnglishClients -= Math.floor(Math.random() * this.totalNonEnglishClients)

                if(this.startDate && this.endDate) {
                    this.isBusyClientsSeen = true
                    this.arrayClientsIndividualClientsSeen = []
                    this.arrayFiltradoReportsGeneralIndividualClientsSeen.filter(item => {
                        // item.addMonthFormat === addMonthFormat
                        var dateFormat = '01/'+item.dateFormat
                        var dateFrom = this.startDate.split("/")
                        var dateTo = this.endDate.split("/")
                        var dateCheck = dateFormat.split("/")
                        // console.log(this.startDate,this.endDate)

                        var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
                        var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
                        var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])
                        if(check >= from && check <= to) {
                            var filterCount = this.arrayFiltradoReportsGeneralClaims.filter(ele => item.idClaims.includes(ele.id))
                            this.arrayClientsIndividualClientsSeen.push({dateFormat: item.dateFormat, countClaims: filterCount.length})
                            // console.log(from,to,check)
                        }
                    })
                    // console.log(this.arrayClientsIndividualClientsSeen)
                    if(this.arrayClientsIndividualClientsSeen.length === 0) {
                        var dateMomentNoExistDate = moment(this.startDate,'DD/MM/YYYY').format('MM/YYYY')
                        this.arrayClientsIndividualClientsSeen.push({dateFormat: dateMomentNoExistDate, countClaims: 0})
                    }
                    // this.arrayClientsIndividualClientsSeen = arrCSeen
                    !this.firstLoadData ? this.isBusyClientsSeen = false : ''
                } else {
                    this.isBusyClientsSeen = true
                    this.arrayClientsIndividualClientsSeen = []
                    this.arrayFiltradoReportsGeneralIndividualClientsSeen.filter(item => {
                        // item.addMonthFormat === addMonthFormat
                        // var dateFormat = '01/'+item.dateFormat
                        // var dateFrom = this.startDate.split("/")
                        // var dateTo = this.endDate.split("/")
                        // var dateCheck = dateFormat.split("/")
                        // console.log(this.startDate,this.endDate)

                        // var from = new Date(dateFrom[2], parseInt(dateFrom[1])-1, dateFrom[0])
                        // var to   = new Date(dateTo[2], parseInt(dateTo[1])-1, dateTo[0])
                        // var check = new Date(dateCheck[2], parseInt(dateCheck[1])-1, dateCheck[0])
                        // if(check >= from && check <= to) {
                            var filterCount = this.arrayFiltradoReportsGeneralClaims.filter(ele => item.idClaims.includes(ele.id))
                            this.arrayClientsIndividualClientsSeen.push({dateFormat: item.dateFormat, countClaims: filterCount.length})
                            // console.log(from,to,check)
                        // }
                    })
                    // console.log(this.arrayClientsIndividualClientsSeen)
                    if(this.arrayClientsIndividualClientsSeen.length === 0) {
                        var dateMomentNoExistDate = moment(this.startDate,'DD/MM/YYYY').format('MM/YYYY')
                        this.arrayClientsIndividualClientsSeen.push({dateFormat: dateMomentNoExistDate, countClaims: 0})
                    }
                    // this.arrayClientsIndividualClientsSeen = arrCSeen
                    !this.firstLoadData ? this.isBusyClientsSeen = false : ''
                }
            } else {
                this.activeFilters = false
                this.arrayClientsIndividualClientsSeen = []
            }
        },
        functionDateRange(val) {
            if(val === 'month') {
                // console.log(this.startDate)
                if(this.startDate) {
                    // '24/03/2022'
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                    // console.log(formatDate1,expiredDate)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(this.startDate,this.endDate)
                }
            } else if(val === 'quarter') {
                if(this.startDate) {
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                }
            } else if(val === 'year') {
                if(this.startDate) {
                    var formatDateNew = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var getYear = moment(formatDateNew).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = '01/01/'+getYear
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var getYear = moment(Date.now()).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = moment('01/01/'+getYear).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,dateUpdateFormat)
                }
            } else if(val === 'x') {
                this.selectedRangeDate = ''
                this.startDate = null
                this.endDate = null
            }
            this.checkActiveFilters()
        },
        activeFilterFunction() {
            this.startDate = null
            this.endDate = null
            this.clinicianName = ''
            this.clientName = ''
            this.noIndividualSessions = null
            this.noGroupSessions = null
            this.noRemoteSessions = null
            this.noAssessmentSessions = null
            this.noCoupleSessions = null
            this.noFamilySessions = null
            this.isInsuranceUtilizedForClaim = ''
            this.clientProgressThisMonth = ''
            this.clientReportsSatisfactionWithTreatmentSessions = ''
            this.didClientProvideCopay = ''
            this.didClientUtilizeSlidingScale = ''
            this.region = ''
            this.clinicianProgram = null
            this.activeFilters = false
            // this.functionFilters()
        },
        filterRestard() {
            this.isBusy = false
            !this.firstLoadData ? this.isBusyClientsSeen = false : ''
            this.loadTotalClients = true
            // db.collection('reportGeneral').doc('counters')
            // .onSnapshot(item => {
            //     // items.forEach(item => {
            //         this.totalGroupSessions = item.data().totalGroupSessions
            //         this.totalIndividualSessions = item.data().totalIndividualSessions
            //         this.totalRemoteSessions = item.data().totalRemoteSessions
            //         this.countClientSatisfaction = item.data().countClientSatisfaction
            //         this.countImproved = item.data().countImproved
            //         this.countNoChange = item.data().countNoChange
            //         this.countDeclined = item.data().countDeclined
            //         this.countTotalSlidingScale = item.data().countTotalSlidingScale
            //         this.countAverageCopayPerSession = item.data().countAverageCopayPerSession
            //         this.totalClaims = item.data().totalClaims
            //         this.isBusy = false
            //     // })
            // })

            // db.collection('reportGeneral').doc('clientsSeen')
            // .onSnapshot(item => {
            //     this.arrayClientsIndividualClientsSeen = item.data().data
            //     this.isBusyClientsSeen = false
            // })

            // this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/general-reports/counters')
            // .then(res => {
            //     res.data.forEach(ele => {
            //         this.totalGroupSessions = ele.totalGroupSessions
            //         this.totalIndividualSessions = ele.totalIndividualSessions
            //         this.totalRemoteSessions = ele.totalRemoteSessions
            //         this.countClientSatisfaction = ele.countClientSatisfaction
            //         this.countImproved = ele.countImproved
            //         this.countNoChange = ele.countNoChange
            //         this.countDeclined = ele.countDeclined
            //         this.countTotalClientsInsured = ele.countTotalClientsInsured
            //         this.countTotalSlidingScale = ele.countTotalSlidingScale
            //         this.countAverageCopayPerSession = ele.countAverageCopayPerSession
            //         this.totalClaims = ele.totalClaims
            //         this.isBusy = false
            //     })
            // })

            // this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/general-reports/clientsSeen')
            // .then(res => {
            //     res.data.forEach(ele => {
            //         this.arrayClientsIndividualClientsSeen = ele.arrayClientsIndividualClientsSeen
            //         this.isBusyClientsSeen = false
            //     })
            // })

            // db.collection('clients').where('status', '==', 'active').where('primaryLanguage', 'not-in', ['English', 'english']).get()
            db.collection('clients').where('primaryLanguage', 'not-in', ['English', 'english']).get()
            .then(docsClients => {
                // console.log(docsClients.size)
                this.totalNonEnglishClients = 0
                this.clientSatisfaction = 0
                docsClients.forEach(itemClient => {
                    if(itemClient.data().primaryLanguage) {
                        var languageLowerCase = itemClient.data().primaryLanguage.toLowerCase()
                        // Total Non-English Clients
                        if(!languageLowerCase.includes("english")) {
                            this.totalNonEnglishClients += 1
                        }
                    } else {
                        this.totalNonEnglishClients += 1
                    }
                })
                this.totalNonEnglishClientsStatic = this.totalNonEnglishClients
                this.loadTotalClients = false
            })
        }
    },
    created() {
        this.firstLoadDOM = true
        this.firstLoadData = true

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/general-reports/claims')
        .then(res => {
            // this.arrayFiltradoReportsGeneralIndividualClientsSeen = res.data.claimsSeenTable
            // console.log('first')
            this.setSnapshotReportsGeneralClaims(res.data)
            this.firstLoadData = false
            this.isBusyClientsSeen = false
        })
        .catch(error => console.log(error))

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/general-reports/claims/clients-seen')
        .then(res => {
            var arraySeen = []
            var filterCounter = 0
            res.data.forEach(ele => {
                var filter = this.seenClients.filter(ele => ele.countClaims === ele.countClaims && ele.dateFormat === ele.dateFormat)
                filter.length === 0 ? filterCounter += 1 : null
                arraySeen.push({
                    countClaims: ele.countClaims,
                    dateFormat: ele.dateFormat,
                })
            })
            // console.log(filterCounter)
            if(filterCounter >= 1) {
                db.collection('reportGeneral').doc('clientsSeen').update({
                    data: arraySeen
                })
            }
            this.arrayFiltradoReportsGeneralIndividualClientsSeen = res.data
            
            // this.setSnapshotReportsGeneralClaims(res.data.general)
            // this.firstLoadData = false
        })
        .catch(error => console.log(error))

        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(doc => {
            this.isDataSpecialist = false
            if(doc.data().role === 'staff') {
                if(doc.data().permissions.includes('data specialist')) {
                    this.isDataSpecialist = true
                }
            } else if(doc.data().role === 'admin') {
                this.isDataSpecialist = true
            }
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/general-reports/counters')
        .then(res => {
            // console.log(res.data)
            res.data.forEach(ele => {
                this.totalGroupSessions = ele.totalGroupSessions
                this.totalIndividualSessions = ele.totalIndividualSessions
                this.totalRemoteSessions = ele.totalRemoteSessions
                this.totalAssessmentSessions = ele.totalAssessmentSessions
                this.totalFamilySessions = ele.totalFamilySessions
                this.totalCoupleSessions = ele.totalCoupleSessions
                this.countClientSatisfaction = ele.countClientSatisfaction
                this.countNA = ele.countNA
                this.countImproved = ele.countImproved
                this.countNoChange = ele.countNoChange
                this.countDeclined = ele.countDeclined
                this.countTotalClientsInsured = ele.countTotalClientsInsured
                this.countTotalClientsInsuredRate = ele.countTotalClientsInsuredRate
                this.countTotalSlidingScale = ele.countTotalSlidingScale
                this.countAverageCopayPerSession = ele.countAverageCopayPerSession
                this.totalClaims = ele.totalClaims
                this.totalCopayWithoutFilters = ele.countAverageCopayPerSession
                this.isBusy = false
            })
        })

        // db.collection('reportGeneral').doc('counters')
        // .onSnapshot(item => {
        //     // items.forEach(item => {
        //         this.totalGroupSessions = item.data().totalGroupSessions
        //         this.totalIndividualSessions = item.data().totalIndividualSessions
        //         this.totalRemoteSessions = item.data().totalRemoteSessions
        //         this.countClientSatisfaction = item.data().countClientSatisfaction
        //         this.countImproved = item.data().countImproved
        //         this.countNoChange = item.data().countNoChange
        //         this.countDeclined = item.data().countDeclined
        //         this.countTotalSlidingScale = item.data().countTotalSlidingScale
        //         this.countAverageCopayPerSession = item.data().countAverageCopayPerSession
        //         this.totalClaims = item.data().totalClaims
        //         this.isBusy = false
        //     // })
        // })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/general-reports/clientsSeen')
        .then(res => {
            // console.log(res.data)
            res.data.forEach(ele => {
                this.seenClients = ele.arrayClientsIndividualClientsSeen
                this.arrayClientsIndividualClientsSeen = ele.arrayClientsIndividualClientsSeen
                this.isBusyClientsSeen = false
            })
        })

        // db.collection('reportGeneral').doc('clientsSeen')
        // .onSnapshot(item => {
        //     this.arrayClientsIndividualClientsSeen = item.data().data
        //     this.isBusyClientsSeen = false
        // })

        // filters
        // this.searchReportsGeneralStartDate('')
        // this.searchReportsGeneralEndDate('')
        // this.searchReportsGeneralClinicianName('')
        // this.searchReportsGeneralClientName('')
        // this.searchReportsGeneralnoIndividualSessions('')
        // this.searchReportsGeneralnoGroupSessions('')
        // this.searchReportsGeneralnoRemoteSessions('')
        // this.searchReportsGeneralnoAssessmentSessions('')
        // this.searchReportsGeneralnoCoupleSessions('')
        // this.searchReportsGeneralnoFamilySessions('')
        // this.searchReportsGeneralisInsuranceUtilizedForClaim('')
        // this.searchReportsGeneralclientProgressThisMonth('')
        // this.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessions('')
        // this.searchReportsGeneraldidClientProvideCopay('')
        // this.searchReportsGeneraldidClientUtilizeSlidingScale('')
        this.startDate = this.searchReportsGeneralStartDateState ? this.searchReportsGeneralStartDateState : null
        this.endDate = this.searchReportsGeneralEndDateState ? this.searchReportsGeneralEndDateState : null
        this.clinicianName = this.searchReportsGeneralClinicianNameState ? this.searchReportsGeneralClinicianNameState : ''
        this.clinicianProgram = this.searchReportsGeneralProgramState ? this.searchReportsGeneralProgramState : null
        // this.clientName = this.searchReportsGeneralClientNameState ? this.searchReportsGeneralClientNameState : ''
        // this.noIndividualSessions = this.searchReportsGeneralnoIndividualSessionsState ? this.searchReportsGeneralnoIndividualSessionsState : null
        // this.noGroupSessions = this.searchReportsGeneralnoGroupSessionsState ? this.searchReportsGeneralnoGroupSessionsState : null
        // this.noRemoteSessions = this.searchReportsGeneralnoRemoteSessionsState ? this.searchReportsGeneralnoRemoteSessionsState : null
        // this.noAssessmentSessions = this.searchReportsGeneralnoAssessmentSessionsState ? this.searchReportsGeneralnoAssessmentSessionsState : null
        // this.noCoupleSessions = this.searchReportsGeneralnoCoupleSessionsState ? this.searchReportsGeneralnoCoupleSessionsState : null
        // this.noFamilySessions = this.searchReportsGeneralnoFamilySessionsState ? this.searchReportsGeneralnoFamilySessionsState : null
        // this.isInsuranceUtilizedForClaim = this.searchReportsGeneralisInsuranceUtilizedForClaimState ? this.searchReportsGeneralisInsuranceUtilizedForClaimState : ''
        // this.clientProgressThisMonth = this.searchReportsGeneralclientProgressThisMonthState ? this.searchReportsGeneralclientProgressThisMonthState : ''
        // this.clientReportsSatisfactionWithTreatmentSessions = this.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessionsState ? this.searchReportsGeneralclientReportsSatisfactionWithTreatmentSessionsState : ''
        // this.didClientProvideCopay = this.searchReportsGeneraldidClientProvideCopayState ? this.searchReportsGeneraldidClientProvideCopayState : ''
        // this.didClientUtilizeSlidingScale = this.searchReportsGeneraldidClientUtilizeSlidingScaleState ? this.searchReportsGeneraldidClientUtilizeSlidingScaleState : ''
        // this.region = this.searchReportsGeneralRegionState ? this.searchReportsGeneralRegionState : ''

        db.collection('clients').where('primaryLanguage', 'not-in', ['English', 'english']).get()
        // db.collection('clients').get()
        .then(docsClients => {
            // console.log(docsClients.size)
            this.totalNonEnglishClients = 0
            this.clientSatisfaction = 0
            docsClients.forEach(itemClient => {
                // if(itemClient.data().status === 'active') {
                    // if(itemClient.id === itemClaim.data().client) {
                        if(itemClient.data().primaryLanguage) {
                            var languageLowerCase = itemClient.data().primaryLanguage.toLowerCase()
                            // Total Non-English Clients
                            if(!languageLowerCase.includes("english")) {
                                this.totalNonEnglishClients += 1
                            }
                        } else {
                            this.totalNonEnglishClients += 1
                        }
                    // }
                    // var nombre = itemClient.data().firstName + ' ' + itemClient.data().lastName
                    // var clientObj = {
                    //     id: itemClient.id,
                    //     nombre,
                    // }
                    // this.optionsClientName.push(clientObj)
                // }
            })
            this.loadTotalClients = false
        })

        db.collection('taxonomies').doc('client').get()
        .then(docTaxonomia => {
            // profile fields
            // this.optionsRegion = docTaxonomia.data().region
            this.optionsLanguage = docTaxonomia.data().primaryLanguage
            this.optionsInsurance = docTaxonomia.data().insuranceType
        })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                if(item !== 'OASAS') {
                    arrayProgram.push({value: item, text: item})
                }
            })
            this.optionsProgram = arrayProgram
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/all-clinicians')
        .then(res => {
            this.optionsClinicianName = [{id: 0, uid: 0, value: 'All Clinicians', full: 'All Clinicians'}]
            res.data.forEach(ele => {
                this.optionsClinicianName.push(ele)
            })
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clients')
        .then(res => { 
            this.optionsClientName = [{id: 0, uid: 0, value: 'All Clients'}]
            res.data.forEach(ele => {
                this.optionsClientName.push(ele)
            })
            // console.log(res.data)
        })

        // db.collection('usuarios').get()
        // .then(docsUsuarios => {
        //     docsUsuarios.forEach(doc => {
        //         if(doc.data().role === 'clinician' && doc.data().status === 'active') {
        //             this.optionsClinicianName.push(doc.data())
        //         }
        //     })
        // })

        // db.collection('clientsActivity')
        // .onSnapshot(docsClients => {
        //     this.itemsActivity = []
        //     docsClients.forEach(dataClientActivity => {
        //         db.collection('clients').doc(dataClientActivity.data().client).get()
        //         .then(itemClient => {
        //             var dateFormat = new Date(dataClientActivity.data().dateOfContact.split('-'))

        //             var obj = {
        //                 clientID: '#' + itemClient.data().id,
        //                 clientName: itemClient.data().firstName + ' ' + itemClient.data().lastName,
        //                 status: dataClientActivity.data().activityStatus,
        //                 contactDate: dataClientActivity.data().dateOfContact,
        //                 contactDateFormat: moment(dateFormat,'DD/MM/YYYY').format('DD/MM/YYYY'),
        //                 admissionDate: dataClientActivity.data().dateOfAdmission ? dataClientActivity.data().dateOfAdmission : '',
        //                 dischargeDate: dataClientActivity.data().dateOfDischarge ? dataClientActivity.data().dateOfDischarge : '',
        //                 region: dataClientActivity.data().regionalCFEReceivingTheCall,
        //                 language: itemClient.data().primaryLanguage,
        //             }
        //             this.itemsActivity.push(obj)
        //         })
        //     })
        //     this.setSnapshotReportsGeneral(this.itemsActivity)
        // })

        
        
        // db.collection('clientsClaims').where('status', '==', 'active').get()
        // .then(docsClaims => {
        //     var arrayClientsIndividualClientsSeenx = []
        //     docsClaims.forEach(itemClaim => {
        //         // Total Sliding Scale
        //         // if(itemClaim.data().didClientUtilizeSlidingScale === 'Yes') {
        //         //     this.countTotalSlidingScale += 1
        //         // }

        //         // if(itemClaim.data().isInsuranceUtilizedForClaim === 'Yes') {
        //         //     // Average Co-pay Per Session
        //         //     if(itemClaim.data().insuranceReimbursementRateForIndividualSessions) {
        //         //         this.countAverageCopayPerSession += parseFloat(itemClaim.data().insuranceReimbursementRateForIndividualSessions)
        //         //     }
        //         // }
        //         // console.log(moment(parseInt(itemClaim.data().created)).format('MM/YYYY'),)
        //         arrayClientsIndividualClientsSeenx.push({
        //             created: moment(parseInt(itemClaim.data().created)).format('MM/YYYY'),
        //             clinician: itemClaim.data().clinician,
        //             region: '',
        //             client: itemClaim.data().client,
        //             noIndividualSessions: itemClaim.data().noIndividualSessions,
        //             noGroupSessions: itemClaim.data().noGroupSessions,
        //             noRemoteSessions: itemClaim.data().noRemoteSessions,
        //             noAssessmentSessions: itemClaim.data().noAssessmentSessions,
        //             noCoupleSessions: itemClaim.data().noCoupleSessions,
        //             noFamilySessions: itemClaim.data().noFamilySessions,
        //             isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim,
        //             clientProgressThisMonth: itemClaim.data().clientProgressThisMonth,
        //             clientReportsSatisfactionWithTreatmentSessions: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions,
        //             didClientProvideCopay: itemClaim.data().didClientProvideCopay,
        //             didClientUtilizeSlidingScale: itemClaim.data().didClientUtilizeSlidingScale,
        //         })
        //     })
            // console.log(arrayClientsIndividualClientsSeenx.length)
            // this.arrayClientsIndividualClientsSeen = arrayClientsIndividualClientsSeen
            // this.setSnapshotReportsGeneralIndividualClientsSeen(arrayClientsIndividualClientsSeenx)
            // db.collection('reportGeneral').doc('clientsSeen').set({
            //     clientsSeen: arrayClientsIndividualClientsSeen
            // })
            // db.collection('reportGeneral').doc('counters').update({
            //     countTotalSlidingScale: this.countTotalSlidingScale,
            //     countAverageCopayPerSession: this.countAverageCopayPerSession
            // })
        // })

        // db.collection('clientsClaims').where('status', '==', 'active').where('approvalStatus', 'in', ['approved','paid']).get()
        // .then(docsClaims => {
            // this.totalClaims = docsClaims.size
            // db.collection('clients').where('status', '==', 'active').get()
            // .then(itemsClients => {
                // this.totalGroupSessions = 0
                // this.totalIndividualSessions = 0
                // this.totalRemoteSessions = 0
                // this.countClientSatisfaction = 0
                // this.countNoChange = 0
                // this.countImproved = 0
                // this.countDeclined = 0
                // this.countTotalSlidingScale = 0

                // this.countAverageCopayPerSession = 0

                // this.improved = 0
                // var arrayClientsClaims = []
                // var arrayClientsIndividualClientsSeen = []
                // docsClaims.forEach(itemClaim => {
                    // console.log(itemClaim.data().length)
                    // if(itemClaim.data().status && itemClaim.data().status === 'active') {
                        // get data client
                        // var region = ''
                        // var language = ''
                        // var statusClient = ''
                        // itemsClients.forEach(itemClient => {
                        //     if(itemClient.id === itemClaim.data().client) {
                        //         region = itemClient.data().countryOfResidence
                        //         language = itemClient.data().primaryLanguage
                        //         statusClient = itemClient.data().status
                        //     }
                        // })

                        // if(statusClient === 'active') {
                            // if(itemClaim.data().approvalStatus === 'approved' || itemClaim.data().approvalStatus === 'paid') {
                            //     // Total Group Sessions
                            //     this.totalGroupSessions += parseInt(itemClaim.data().noGroupSessions, 10)

                            //     // Total Individual Sessions
                            //     this.totalIndividualSessions += parseInt(itemClaim.data().noIndividualSessions, 10)

                            //     // Total Remote Sessions
                            //     this.totalRemoteSessions += parseInt(itemClaim.data().noRemoteSessions, 10)

                            //     if(itemClaim.data().clientReportsSatisfactionWithTreatmentSessions) {
                            //         // Client Satisfaction
                            //         if(itemClaim.data().clientReportsSatisfactionWithTreatmentSessions === 'Yes') {
                            //             this.countClientSatisfaction += 1
                            //         }
                            //     }

                            //     // Improved
                            //     if(itemClaim.data().clientProgressThisMonth === 'Improved') {
                            //         this.countImproved += 1
                            //     }

                            //     // No Change
                            //     if(itemClaim.data().clientProgressThisMonth === 'No Change') {
                            //         this.countNoChange += 1
                            //     }

                            //     // Declined
                            //     if(itemClaim.data().clientProgressThisMonth === 'Declined') {
                            //         this.countDeclined += 1
                            //     }
                            // }

                            // // Total Sliding Scale
                            // if(itemClaim.data().didClientUtilizeSlidingScale === 'Yes') {
                            //     this.countTotalSlidingScale += 1
                            // }

                            // if(itemClaim.data().isInsuranceUtilizedForClaim === 'Yes') {
                            //     // Average Co-pay Per Session
                            //     if(itemClaim.data().insuranceReimbursementRateForIndividualSessions) {
                            //         this.countAverageCopayPerSession += parseFloat(itemClaim.data().insuranceReimbursementRateForIndividualSessions)
                            //     }
                            // }

                            // var obj = {
                            //     totalGroupSessions: parseInt(itemClaim.data().noGroupSessions, 10),
                            //     totalIndividualSessions: parseInt(itemClaim.data().noIndividualSessions, 10),
                            //     totalRemoteSessions: parseInt(itemClaim.data().noRemoteSessions, 10),
                            //     client: itemClaim.data().client,
                            //     clinician: itemClaim.data().clinician,
                            //     region: region,
                            //     language: language,
                            //     approvalStatus: itemClaim.data().approvalStatus,
                            //     clientReportsSatisfactionWithTreatmentSessions: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions,
                            //     clientProgressThisMonth: itemClaim.data().clientProgressThisMonth,
                            //     didClientUtilizeSlidingScale: itemClaim.data().didClientUtilizeSlidingScale,

                            //     noIndividualSessions: itemClaim.data().noIndividualSessions,
                            //     noGroupSessions: itemClaim.data().noGroupSessions,
                            //     noRemoteSessions: itemClaim.data().noRemoteSessions,
                            //     noAssessmentSessions: itemClaim.data().noAssessmentSessions,
                            //     noCoupleSessions: itemClaim.data().noCoupleSessions,
                            //     noFamilySessions: itemClaim.data().noFamilySessions,
                            //     isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim,
                            //     clientProgressThisMonth: itemClaim.data().clientProgressThisMonth,
                            //     clientReportsSatisfactionWithTreatmentSessions: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions,
                            //     didClientProvideCopay: itemClaim.data().didClientProvideCopay,
                            //     didClientUtilizeSlidingScale: itemClaim.data().didClientUtilizeSlidingScale,

                            //     isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim,
                            //     insuranceReimbursementRateForIndividualSessions: itemClaim.data().insuranceReimbursementRateForIndividualSessions,
                            //     claimDate: '01/' + moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear,
                            //     claimMonthYear: moment().month(itemClaim.data().claimDateMonth).format('MM') + '/' + itemClaim.data().claimDateYear,
                            //     createdFormat: moment(itemClaim.data().created).format('DD/MM/YYYY'),
                            //     created: moment(itemClaim.data().created).format('MM/YYYY'),
                            //     actualDate: moment().format('MM/YYYY')
                            // }
                            // arrayClientsClaims.push(obj)

                            // arrayClientsIndividualClientsSeen.push({
                            //     created: moment(itemClaim.data().created).format('MM/YYYY'),
                            //     clinician: itemClaim.data().clinician,
                            //     region: region,
                            //     client: itemClaim.data().client,
                            //     noIndividualSessions: itemClaim.data().noIndividualSessions,
                            //     noGroupSessions: itemClaim.data().noGroupSessions,
                            //     noRemoteSessions: itemClaim.data().noRemoteSessions,
                            //     noAssessmentSessions: itemClaim.data().noAssessmentSessions,
                            //     noCoupleSessions: itemClaim.data().noCoupleSessions,
                            //     noFamilySessions: itemClaim.data().noFamilySessions,
                            //     isInsuranceUtilizedForClaim: itemClaim.data().isInsuranceUtilizedForClaim,
                            //     clientProgressThisMonth: itemClaim.data().clientProgressThisMonth,
                            //     clientReportsSatisfactionWithTreatmentSessions: itemClaim.data().clientReportsSatisfactionWithTreatmentSessions,
                            //     didClientProvideCopay: itemClaim.data().didClientProvideCopay,
                            //     didClientUtilizeSlidingScale: itemClaim.data().didClientUtilizeSlidingScale,
                            // })

                            // this.improved += 1
                        // }
                    // }
                // })

                // db.collection('reportGeneral').doc('counters').set({
                //     totalGroupSessions: this.totalGroupSessions,
                //     totalIndividualSessions: this.totalIndividualSessions,
                //     totalRemoteSessions: this.totalRemoteSessions,
                //     countClientSatisfaction: this.countClientSatisfaction,
                //     countImproved: this.countImproved,
                //     countNoChange: this.countNoChange,
                //     countDeclined: this.countDeclined,
                //     countTotalSlidingScale: this.countTotalSlidingScale,
                //     countAverageCopayPerSession: this.countAverageCopayPerSession,
                //     totalClaims: this.totalClaims,
                // })
                // this.setSnapshotReportsGeneralIndividualClientsSeen(arrayClientsIndividualClientsSeen)
                // this.setSnapshotReportsGeneralClaims(arrayClientsClaims)
            // })
        // })
    }
}
</script>

<style>
.mx-input {
    height: 37px !important;
}
/* .inputReport .vs__dropdown-toggle {
    height: 37px !important;
}
.inputReport .v-select.vs--single .vs__selected {
    margin-top: 2px !important;
} */
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.range-date {
    padding: 8px;
    margin-top: 27px;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>